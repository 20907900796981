<template>
  <div>
    <b-overlay
      :show="saveStatus"
      rounded="sm"
    >
      <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Editar Proveedor"
      back-button-text="Anterior"
      next-button-text="Siguiente"
      class="steps-transparent mb-3"
      @on-complete="saveData"
      >
      <!-- company detail tab -->
      <tab-content
          title="Datos generales"
          icon="feather icon-file-text"
      >
          <b-row>
          <b-col
              cols="12"
              class="mb-2"
          >
              <h5 class="mb-0">
              Datos de la empresa
              </h5>
              <small class="text-muted">
            Ingrese los datos que se piden.
              </small>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="Nombre de la empresa"
              label-for="i-empresa"
              >
              <b-form-input
                  id="i-empresa"
                  placeholder="Servicios de reciclaje S.A de C.V"
                  @keypress="detectUnsavedChange"
                  v-model="companyData.companyName"
                  :state="validator.companyName"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="Email"
              label-for="i-email"
              >
              <b-form-input
                  id="i-email"
                  type="email"
                  placeholder="ejemplo@email.com"
                  @keypress="detectUnsavedChange"
                  v-model="companyData.companyEmail"
                  :state="validator.companyEmail"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="Teléfono"
              label-for="i-phone"
              >
              <b-form-input
                  id="i-phone"
                  placeholder="8341893478"
                  @keypress="restrictNumber($event)"
                  v-model="companyData.companyPhone"
                  :state="validator.companyPhone"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="RFC"
              label-for="i-c-rfc"
              >
              <b-form-input
                  id="i-c-rfc"
                  placeholder="SERC98233819"
                  @keypress="detectUnsavedChange"
                  v-model="companyData.companyRFC"
                  :state="validator.companyRFC"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="Moneda"
              label-for="i-c-currency"
              >
              <v-select
                :options="currencies"
                label="name"
                input-id="invoice-data-client"
                :clearable="false"
                placeholder="Selecciona la moneda que usa la empresa"
                :reduce="name => name.id"
                v-on:input="detectUnsavedChange"
                v-model="companyData.companyCurrency"
                :class="(validator.companyCurrency == false) ? 'false-state' : null"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="Unidad de medida"
              label-for="i-c-measure"
              >
              <v-select
                :options="measurementUnits"
                label="name"
                input-id="invoice-data-client"
                :clearable="false"
                placeholder="Selecciona la unidad de medida que usa la empresa"
                :reduce="name => name.id"
                v-on:input="detectUnsavedChange"
                v-model="companyData.companyMeasurementUnit"
                :class="(validator.companyMeasurementUnit == false) ? 'false-state' : null"
              />
              </b-form-group>
          </b-col>
          </b-row>
      </tab-content>

      <!-- address -->
      <tab-content
          title="Dirección"
          icon="feather icon-map-pin"
      >
          <b-row>
          <b-col
              cols="12"
              class="mb-2"
          >
              <h5 class="mb-0">
                Dirección
              </h5>
              <small class="text-muted">Ingrese los datos de la dirección</small>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="Calle"
              label-for="i-street"
              >
              <b-form-input
                  id="i-street"
                  placeholder="Av. Alfonso Reyes #762"
                  @keypress="detectUnsavedChange"
                  v-model="companyData.address.street"
                  :state="validator.street"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="Colonia"
              label-for="i-colony"
              >
              <b-form-input
                  id="i-colony"
                  placeholder="Las Flores"
                  @keypress="detectUnsavedChange"
                  v-model="companyData.address.colony"
                  :state="validator.colony"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label="CP"
              label-for="i-pincode"
              >
              <b-form-input
                  id="i-pincode"
                  type="number"
                  @keypress="restrictNumber($event)"
                  placeholder="658921"
                  :formatter="formatCP"
                  v-model="companyData.address.cp"
                  :state="validator.cp"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label-for="i-country"
              label="Pais"
              >
              <v-select
                :options="countries"
                label="name"
                input-id="invoice-data-client"
                :clearable="false"
                placeholder="Selecciona el país de la empresa"
                :reduce="name => name.id"
                @input="getStateByCountry($event)"
                v-on:input="detectUnsavedChange"
                v-model="companyData.address.idCountry"
                :class="(validator.idCountry == false) ? 'false-state' : null"
              />
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label-for="i-state"
              label="Estado"
              >
              <v-select
                :options="states"
                label="name"
                input-id="invoice-data-client"
                :clearable="false"
                placeholder="Selecciona el estado de la empresa"
                :reduce="name => name.id"
                v-on:input="detectUnsavedChange"
                v-model="companyData.address.idState"
                :class="(validator.idState == false) ? 'false-state' : null"
              >
                <span slot="no-options">Por favor, selecciona un país primero.</span>
              </v-select>
              </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
              label-for="i-city"
              label="Ciudad"
              >
              <b-form-input
                  id="i-city"
                  placeholder="Birmingham"
                  @keypress="detectUnsavedChange"
                  v-model="companyData.address.city"
                  :state="validator.city"
              />
              </b-form-group>
          </b-col>
          </b-row>
      </tab-content>

      <!-- route details -->
      <tab-content
          title="Ruta"
          icon="feather icon-truck"
      >
        <b-row>
          <b-col
              cols="12"
              class="mb-2"
          >
              <h5 class="mb-0">
              Información de la ruta
              </h5>
              <small class="text-muted">Selecciona una o varias rutas y verifica la información</small>
          </b-col>
          <!-- Route Section -->
          <b-col md="12">
            <b-card-body class="invoice-padding form-item-section rounded">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: 10}"
              >
                <b-row
                  v-for="(route, indexRoute) in companyData.routes"
                  :key="indexRoute"
                  ref="row"
                  class="pb-2"
                >
                  <b-col cols="12">
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col md="12">
                          <b-form-group
                            label-for="i-route"
                            label="Ruta"
                          >
                          <v-select
                            :options="routes"
                            label="name"
                            :clearable="false"
                            placeholder="Selecciona la ruta que usa la empresa"
                            :reduce="name => name.id"
                            v-on:input="detectUnsavedChange"
                            v-model="companyData.routes[indexRoute].idRoute"
                            @input="getRouteData($event, indexRoute)"
                          />
                          </b-form-group>
                        </b-col>
                        <template v-if="showRoutes[indexRoute]">
                          <b-col md="6">
                            <b-form-group
                            label="Nombre del stock"
                            label-for="n-stock"
                            >
                            <b-form-input
                              id="n-stock"
                              disabled
                              v-model="routeData[indexRoute][0].stockName"
                            />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                            label="Indicador del stock"
                            label-for="i-stock"
                            >
                            <div v-if="routeData[indexRoute][0].stockIndicator == 0">
                              <b-form-input
                                id="i-stock"
                                disabled
                                value="Actual"
                              />
                            </div>
                            <div v-else>
                              <b-form-input
                                id="i-stock"
                                disabled
                                :value="routeData[indexRoute][0].stockIndicator + ' días'"
                              />
                            </div>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                            label="Precio"
                            label-for="i-price"
                            >
                            <b-form-input
                              id="i-price"
                              disabled
                              :value="'$' + formatterNumber(routeData[indexRoute][0].basePrice) + ' (' + routeData[indexRoute][0].currency + ')'"
                            />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                            label="Peso"
                            label-for="i-weight"
                            >
                            <b-form-input
                              id="i-weight"
                              disabled
                              :value="formatterNumber(routeData[indexRoute][0].weight) + ' (' + routeData[indexRoute][0].measurementUnit + ')'"
                            />
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group
                            label="Comisiones"
                            label-for="commission-list"
                            >
                            <b-form-textarea
                              id="commission-list"
                              disabled
                              no-resize
                              :value="formatterCommission(routeData[indexRoute][0])"
                            />
                            </b-form-group>
                          </b-col>
                        </template>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(null, null, indexRoute, 'route')"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="addNewItem(null, 'route')"
              >
                Agregar una ruta
              </b-button>
            </b-card-body>
          </b-col>
        </b-row>
      </tab-content>

      <!-- metal link -->
      <tab-content
          title="Metales y comisiones"
          icon="feather icon-box"
      >
        <b-row>
        <b-col
            cols="12"
            class="mb-2"
        >
            <h5 class="mb-0">
            Metales
            </h5>
            <small class="text-muted">Seleccione los metales que maneja el cliente</small>
        </b-col>
        <b-col md="12">
          <!-- Metal commission Section -->
          <b-card-body class="invoice-padding form-item-section rounded">
            <div
              ref="form"
              class="repeater-form"
              :style="{height: 10}"
            >
              <b-row
                v-for="(item, indexItem) in companyData.items"
                :key="indexItem"
                ref="row"
                class="pb-2"
              >

                <!-- Item Form -->
                <!-- ? This will be in loop => So consider below markup for single item -->
                <b-col cols="12">
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <div class="d-none d-lg-flex">
                    <b-row class="flex-grow-1 px-1">
                      <!-- Single Item Form Headers -->
                      <b-col
                        cols="12"
                        lg="4"
                      >
                        Metal
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        v-if="false"
                      >
                        Ganancias
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        v-if="false"
                      >
                        Indicador
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        v-if="false"
                      >
                        Bolsa
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        v-if="false"
                      >
                        Indicador
                      </b-col>
                    </b-row>
                    <div class="form-item-action-col" />
                  </div>

                  <!-- Form Input Fields OR content inside bordered area  -->
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <!-- Item ROW -->
                  <div class="d-flex border rounded">
                    <b-row class="flex-grow-1 p-2">
                      <!-- METAL -->
                      <b-col cols="12" lg="12">
                        <label class="d-inline d-lg-none">Item</label>
                        <v-select
                          :options="items"
                          label="name"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Selecciona un metal"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model='companyData.items[indexItem].idItem'
                          :class="(validator.metals[indexItem].idItem == false) ? 'false-state' : null"
                        />
                      </b-col>
                      <!-- PROFIT -->
                      <b-col cols="12" lg="2" v-if="false">
                        <label class="d-inline d-lg-none">Ganancia</label>
                        <b-form-input
                          @keypress="restrictNumber($event)"
                          type="number"
                          class="mb-2"
                          v-model="companyData.items[indexItem].profit"
                          :state="validator.metals[indexItem].profit"
                        />
                      </b-col>
                      <!-- Indicador profit -->
                      <b-col cols="12" lg="2" v-if="false">
                        <label class="d-inline d-lg-none">Indicador</label>
                        <v-select
                          :options="profitIndicator"
                          label="name"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Selecciona un indicador"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model='companyData.items[indexItem].profitAction'
                          :class="(validator.metals[indexItem].profitAction == false) ? 'false-state' : null"
                        />
                      </b-col>
                      <!-- Bolsa -->
                      <b-col cols="12" lg="2" v-if="false">
                        <label class="d-inline d-lg-none">Bolsa</label>
                        <v-select
                          :options="stockNames"
                          label="name"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Selecciona una bolsa"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model='companyData.items[indexItem].idStockName'
                          :class="(validator.metals[indexItem].idStockName == false) ? 'false-state' : null"
                        />
                      </b-col>
                      <!-- Indicador bolsa -->
                      <b-col cols="12" lg="2" v-if="false">
                        <label class="d-inline d-lg-none">Indicador</label>
                        <v-select
                          :options="stockIndicator"
                          label="name"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Selecciona un indicador"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model='companyData.items[indexItem].timeseries'
                          :class="(validator.metals[indexItem].timeseries == false) ? 'false-state' : null"
                        />
                      </b-col>
                      <!-- Linea -->
                      <b-col cols="12">
                        <hr style="height:1px;border-width:0;color:gray;background-color:gray">
                      </b-col>
                      <!-- PROVEEDORES -->
                      <b-col>
                        <div>
                          <div>
                            <b-form
                              ref="form"
                              class="repeater-form"
                            >
                              <!-- Row Loop -->
                              <b-row
                                v-for="(commission, indexCommission) in companyData.items[indexItem].commissions"
                                :id="commission.idCommission"
                                :key="indexCommission"
                                ref="row"
                              >
                                <!-- Comision -->
                                <b-col cols="12" lg="10">
                                  <b-form-group
                                    label="Comisión"
                                    label-for="item-name"
                                  >
                                    <v-select
                                      :options="commissions"
                                      label="name"
                                      input-id="invoice-data-client"
                                      :clearable="false"
                                      placeholder="Selecciona una comisión"
                                      :reduce="name => name.id"
                                      v-on:input="detectUnsavedChange"
                                      v-model='companyData.items[indexItem].commissions[indexCommission].idCommission'
                                    />
                                  </b-form-group>
                                </b-col>
                                
                                <b-col cols="12" lg="2"
                                style="
                                  text-align: center;
                                  margin-bottom: 1em;
                                ">
                                  <b-button
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2"
                                    @click="removeItem(indexItem, indexCommission, null, 'commission')"
                                  >
                                    <feather-icon
                                      icon="XIcon"
                                      class="mr-25"
                                    />
                                    <span>Eliminar</span>
                                  </b-button>
                                </b-col>
                              </b-row>

                            </b-form>
                          </div>
                          <div style="text-align: center;">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-primary"
                              @click="addNewItem(indexItem, 'commission')"
                            >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-25"
                            />
                            <span>Agregar otra comisión</span>
                          </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeItem(indexItem, null, null, 'item')"
                        v-if="false"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="outline-primary"
              @click="addNewItem(null, 'item')"
              v-if="false"
            >
              Agregar un metal
            </b-button>
          </b-card-body>
        </b-col>
        </b-row>
      </tab-content>
      </form-wizard>
    </b-overlay>
    <!-- MODAL ALERTA -->
    <b-modal
      id="alertModal"
      title="Alerta"
      ok-title="Voy a revisarlo"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <span style="white-space: pre-line">Favor de llenar los campos requeridos faltantes:
          {{ missingParameters }}</span>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.false-state {
  border-radius: 6px;
  border: 1px solid #f66;
  color: #f66;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'


// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, 
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle,
BOverlay } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent,
    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,
    BOverlay,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Catalogs
      items: [],
      currencies: [],
      measurementUnits: [],
      countries: [],
      states: [],
      commissions: [],
      routes: [],
      stockNames: [],
      stockIndicator: [
        {
          id: 0,
          name: "Actual",
        },
        {
          id: 7,
          name: "7 días",
        },
        {
          id: 30,
          name: "30 días",
        },
      ],
      profitIndicator: [
        {
          id: '%',
          name: "%",
        },
        {
          id: '$',
          name: "$",
        },
      ],
      routeData: [ 
        { 
          name: null,
          id: null,
          basePrice: null, 
          weight: null,
          stockName: null,
          currency: null,
          measurementUnit: null,
          originState: null,
          destinyState: null 
        },
      ],
      showRoutes: [],

      // Templates
      itemTemplate: {
        idItem: null,
        profit: null,
        profitAction: null,
        idStockName: null,
        timeseries: null,
        commissions: [
          {
            idCommission: null,
          }
        ]
      },
      commissionTemplate: {
        idCommission: null,
      },
      routeTemplate: {
        idRoute: null,
      },
      routeDataTemplate: {
        name: null,
        id: null,
        basePrice: null, 
        weight: null,
        stockName: null,
        currency: null,
        measurementUnit: null,
        originState: null,
        destinyState: null 
      },

      // Metal Commission
      companyData: {
        idCompany: null,
        companyName: null,
        companyEmail: null,
        companyPhone: null,
        companyRFC: null,
        companyCurrency: null,
        companyMeasurementUnit: null,
        address: {
          street: null,
          colony: null,
          cp: null,
          idCountry: null,
          idState: null,
          city: null,
        },
        routes: [
          {
            idRoute: null,
          },
        ],
        items: [
          JSON.parse(JSON.stringify(
          {
            idItem: null,
            profit: null,
            profitAction: null,
            idStockName: null,
            timeseries: null,
            commissions: [
              {
                idCommission: null,
              }
            ]
          }))
        ]
      },

      // Props
      saveStatus: true,
      byPassRouteLeave: true,
      logisticPrices: [],

      // Validations
      validator: {
        companyName: null,
        companyEmail: null,
        companyPhone: null,
        companyRFC: null,
        companyCurrency: null,
        companyMeasurementUnit: null,
        street: null,
        colony: null,
        cp: null,
        idCountry: null,
        idState: null,
        city: null,
        metals: [
          {
            idItem: null,
            profit: null,
            profitAction: null,
            idStockName: null,
            timeseries: null,
          }
        ]
      },
      validatorMetalTemplate: {
        idItem: null,
        profit: null,
        profitAction: null,
        idStockName: null,
        timeseries: null,
      },
      missingParameters: ''
    }
  },
  created() {
    // Get currencies and units
    this.getUnits();
  },
  beforeRouteLeave(to, from, next) { // Evitar que se salgan de la pantalla y perder datos
    if (!(this.byPassRouteLeave)) {
      const answer = window.confirm('¿Seguro que desea salir? Los cambios no guardados se perderán.');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    getCompany() {
      this.$axios.get(this.$services + 'providers/get_provider', {
        params: {
          idCompany: router.currentRoute.params.id,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.companyData = res.data.data;
          this.getStateByCountry(this.companyData.address.idCountry);
          this.routeData = this.routes.filter(route => route.id == this.companyData.companyRoute);
          for (let i = 0; i < this.companyData.routes.length; i++) {
            this.showRoutes.push(false)
            this.getRouteData(this.companyData.routes[i].idRoute, i);
          }
          for (let i = 1; i < this.companyData.items.length; i++)
            this.validator.metals.push(this.validatorMetalTemplate);
        }
        this.saveStatus = false;
      }).catch(error => console.log(error));
    },
    getUnits() {
      this.$axios.get(this.$services + 'general/get_units').then(res => {
        this.this.$refreshToken(res.headers['x-access-token']);
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.currencies = res.data.data.currencies;
          this.measurementUnits = res.data.data.measurementUnits;
          this.getCountries();
        }
      }).catch(error => {
         this.this.$verifyToken(error.response.data.data);
      });
    },
    getCountries() {
      this.$axios.get(this.$services + 'general/get_countries').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.countries = res.data.data;
          this.getMetals();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getStateByCountry(idCountry) {
      this.$axios.get(this.$services + 'general/get_states_country', {
        params: {
          idCountry: idCountry
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.states = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getMetals() {
      this.$axios.get(this.$services + 'general/get_metals').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.items = res.data.data;
          this.getCommissions();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getCommissions() {
      this.$axios.get(this.$services + 'commissions/get_commissions').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.commissions = res.data.data;
          this.getRoutes();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getRoutes() {
      this.$axios.get(this.$services + 'logistics/get_routes').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.routes = res.data.data;
          for (let route of this.routes)
            this.getLogisticPrice(route);
          this.getStockNames();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getRouteData(data, indexRoute) {
      this.routeData[indexRoute] = this.routes.filter(route => route.id == data);
      this.showRoutes[indexRoute] = true;
      this.logisticPrices.push('Clic para calcular');
    },
    getStockNames() {
      this.$axios.get(this.$services + 'prices/get_stocknames').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.stockNames = res.data.data;
          this.getCompany();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getLogisticPrice(data) {
      let price;
      data.basePrice = parseInt(data.basePrice);
      data.weight = parseInt(data.weight);
      this.$axios.post(this.$services + 'logistics/get_logistic_price', {
        routeData: data,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          price = res.data.data.logisticPrice + ' ' + res.data.data.currency + ' / ' + res.data.data.measurementUnit;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
        price = 'Error';
      }).finally(() => {
        data.logisticPrice = price;
      });
    },
    saveData() {
      // Checar que no falten parámetros
      this.checkMissingParameters() ? this.$bvModal.show('alertModal') : this.updateCompany();
    },
    checkSingleParameter(property, missing, missingParameters, text, validator) {

      // Que no esté vacía la propiedad
      if (property == null || property === '') {
        missing = true;
        missingParameters += text;
        validator = false;
      } else
        validator = null;

      return [missing, missingParameters, validator];
    },
    checkMissingParameters() {

      // Default
      let missing = false;
      this.missingParameters = '\n\n';

      // Checar datos generales
      [missing, this.missingParameters, this.validator.companyName] = this.checkSingleParameter(
        this.companyData.companyName, missing, this.missingParameters, '• Nombre de la empresa\n', this.validator.companyName
      );
      [missing, this.missingParameters, this.validator.companyEmail] = this.checkSingleParameter(
        this.companyData.companyEmail, missing, this.missingParameters, '• Email de la empresa\n', this.validator.companyEmail
      );
      [missing, this.missingParameters, this.validator.companyPhone] = this.checkSingleParameter(
        this.companyData.companyPhone, missing, this.missingParameters, '• Teléfono de la empresa\n', this.validator.companyPhone
      );
      [missing, this.missingParameters, this.validator.companyRFC] = this.checkSingleParameter(
        this.companyData.companyRFC, missing, this.missingParameters, '• RFC de la empresa\n', this.validator.companyRFC
      );
      [missing, this.missingParameters, this.validator.companyCurrency] = this.checkSingleParameter(
        this.companyData.companyCurrency, missing, this.missingParameters, '• Moneda de la empresa\n', this.validator.companyCurrency
      );
      [missing, this.missingParameters, this.validator.companyMeasurementUnit] = this.checkSingleParameter(
        this.companyData.companyMeasurementUnit, missing, this.missingParameters, '• Unidad de medida de la empresa\n', this.validator.companyMeasurementUnit
      );

      // Checkar dirección
      [missing, this.missingParameters, this.validator.street] = this.checkSingleParameter(
        this.companyData.address.street, missing, this.missingParameters, '• Nombre de la calle\n', this.validator.street
      );
      [missing, this.missingParameters, this.validator.colony] = this.checkSingleParameter(
        this.companyData.address.colony, missing, this.missingParameters, '• Nombre de la colonia\n', this.validator.colony
      );
      [missing, this.missingParameters, this.validator.cp] = this.checkSingleParameter(
        this.companyData.address.cp, missing, this.missingParameters, '• Código postal\n', this.validator.cp
      );
      [missing, this.missingParameters, this.validator.idCountry] = this.checkSingleParameter(
        this.companyData.address.idCountry, missing, this.missingParameters, '• País\n', this.validator.idCountry
      );
      [missing, this.missingParameters, this.validator.idState] = this.checkSingleParameter(
        this.companyData.address.idState, missing, this.missingParameters, '• Estado\n', this.validator.idState
      );
      [missing, this.missingParameters, this.validator.city] = this.checkSingleParameter(
        this.companyData.address.city, missing, this.missingParameters, '• Nombre de la ciudad\n', this.validator.city
      );

      // Al menos un metal
      if (this.companyData.items.length == 0) {
        missing = true;
        this.missingParameters += '• Al menos un metal';
      }

      // Checkar metales y comisiones
      for (const [index, item] of this.companyData.items.entries()) {
        [missing, this.missingParameters, this.validator.metals[index].idItem] = this.checkSingleParameter(
          item.idItem, missing, this.missingParameters, '• Nombre del metal núm. ' + (index+1) + '\n', this.validator.metals[index].idItem
        );
      }

      return missing;
    },
    updateCompany() {
      this.saveStatus = true;
      this.$axios.post(this.$services + 'providers/update_company', {
        companyData: this.companyData,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', '👋  ¡Proveedor actualizado con éxito!');
          this.saveStatus = false;
          this.byPassRouteLeave = true;
          this.$router.push({ name: 'providers' });
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    addNewItem(indexItem, type) {
      if (type === 'item') {
        this.companyData.items.push(JSON.parse(JSON.stringify(this.itemTemplate)));
        this.validator.metals.push(JSON.parse(JSON.stringify(this.validatorMetalTemplate)));
      } else if (type === 'commission') {
        this.companyData.items[indexItem].commissions.push(JSON.parse(JSON.stringify(this.commissionTemplate)));
      } else if (type === 'route') {
        this.companyData.routes.push(JSON.parse(JSON.stringify(this.routeTemplate)));
        this.routeData.push(JSON.parse(JSON.stringify(this.routeDataTemplate)));
        this.showRoutes.push(false);
        this.logisticPrices.push('Clic para calcular');
      }
    },
    removeItem(indexItem, indexCommission, indexRoute, type) {
      if (type === 'item') {
        this.companyData.items.splice(indexItem, 1);
        this.validator.metals.splice(indexItem, 1);
      } else if (type === 'commission') {
        this.companyData.items[indexItem].commissions.splice(indexCommission, 1);
      } else if (type === 'route') {
        this.companyData.routes.splice(indexRoute, 1);
        this.routeData.splice(indexRoute, 1);
        this.showRoutes.splice(indexRoute, 1);
        this.logisticPrices.splice(indexRoute, 1);
      }
    },
    detectUnsavedChange() {
      this.byPassRouteLeave = false;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    restrictNumber(event) { // Solo numeros pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
        return;
      }
      this.byPassRouteLeave = false;
    },
    formatCP(e) {
      return String(e).substring(0,6);
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
    formatterCommission(data) {
      let format = '';

      // Comisiones
      data.commissions.forEach(commission => {
        format += commission.commissionName + ' ';
        format += '$' + String(commission.price) + ' ';
        format += '(' + commission.commissionCurrency + ')';
        format += '\n'
      });

      // Precio de logística
      format += 'Precio de logística: ' + data.logisticPrice;

      return format;
    },
    // TODO TERMINAR
    printInvoice() {
      window.print()
    },
  },
  computed: {
  },
}
</script>
